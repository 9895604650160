<template>
  <v-container class="bg-glass-effect-lighter pt-10 fh" fluid>
    <Back :route="'root'"/>
    <div class="notification-header">
      <v-row justify="space-around" align="center" class="px-6">
        <v-col class="px-0 mx-0" cols="12">
          <h2 class="pa-0 pt-3 ma-0 generic-title">Messenger Protegido</h2>
        </v-col>
        <v-row justify="center" align="center" class="pt-2 pb-10">
          <v-col class="centralize" cols="12">
            <v-card style="background: rgba(231, 234, 254, .6);" class="pt-3 option-list-card list-card">
              <v-row justify="center">
                <v-col class="pr-0 pl-6 pt-2" cols="6">
                  <div
                    :class="hasConnectionCompany() && getActiveFilter ? 'list-card pt-3' : 'pt-3 pb-1'"
                    :style="hasConnectionCompany() && getActiveFilter && 'background: white; height: 50px'"
                  >
                    <p
                      style="font-size: 16px; font-weight: bolder"
                      class="text-center generic-text mb-0"
                      @click="() => {if(hasConnectionCompany()) activeFilter = true}"
                    >
                      <span :style="!hasConnectionCompany() && 'color: transparent !important;'">
                        Chat
                        <v-badge v-if="newChatCount" class="pl-2 mt-2" :content="newChatCount" color="red"/>
                      </span>
                    </p>

                  </div>
                </v-col>
                <v-col class="pr-6 pl-0 pt-2" cols="6">
                  <div
                      :class="!getActiveFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
                      :style="!getActiveFilter && 'background: white; height: 50px'"
                  >
                    <p
                        style="font-size: 16px; font-weight: bolder"
                        class="text-center generic-text mb-0"
                        @click="() => activeFilter = false"
                    >
                      Notificações
                      <v-badge v-if="newMessagesCount" class="pl-1 mt-2" :content="newMessagesCount" color="red"/>
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
<!--        <v-row class="px-1 pr-9 mb-5" v-if="!getMessagesGroupingBySender() || getMessagesGroupingBySender().length == 0">-->
<!--          <v-col cols="12">-->
<!--            <v-divider></v-divider>-->
<!--            <p class="generic-title text-center message-body my-4">Você não possui mensagens</p>-->
<!--            <v-divider></v-divider>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-row>
    </div>
    <v-row justify="center" align="center" v-if="activeFilter" class="px-6 notification-body">
      <v-row v-for="(chatBot) in chatBots" :key="chatBot.id" class="fw" @click="showChat(chatBot)">
        <v-col cols="2">
          <v-avatar size="48">
            <img :src="chatBot.thumbnail" >
          </v-avatar>
        </v-col>
        <v-col cols="10" class="pl-5">
          <v-row align="end">
            <v-col cols="12" class="message-sender generic-title pl-2 pa-0 pt-2">
              {{ chatBot.name }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="message-body pl-2 pa-0 text-truncate">
              {{ chatBot.lastMessage ? chatBot.lastMessage : chatBot.description }}
            </v-col>
            <v-col cols="2" class="pa-0 ma-0" style="position: relative; right: 10px; bottom: 12px">
              <pulse-icon v-if="chatBot.unreadMessages" :content="chatBot.unreadMessages" color="red" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-else class="px-6 notification-body">
      <v-row v-for="(message) in getMessagesGroupingBySender()" :key="message.id" class="fw" @click="showNotifications(message.senderEntity)">
        <v-col cols="2">
          <v-avatar size="48">
            <img :src="message.senderEntity.thumbnail" >
          </v-avatar>
        </v-col>
        <v-col cols="10" class="pl-5">
          <v-row align="end">
            <v-col cols="7" class="message-sender generic-title pl-2 pa-0 pt-2">
              {{ message.senderEntity.name }}
            </v-col>
            <v-col cols="5" class="pa-0 pr-3 text-right">
              <p style="margin-bottom: 6px" class="message-time">{{getMessageDate(message.lastMessage.message.sentDateTime)}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="message-body pl-2 pa-0 text-truncate">
              <span>{{ message.lastMessage.message.body }}</span>
            </v-col>
            <v-col cols="2" class="py-0 text-left">
              <pulseIcon v-if="message.unreadMessages" :content="message.unreadMessages" color="red" />
            </v-col>
          </v-row>
          <v-row>
            <v-card v-if="message.lastMessage.message.messageType" class="message-inside-card mt-2" :set="messageInside = JSON.parse(message.lastMessage.message.messageType)">
              <v-card-text class="py-0 px-3">
                <v-row class="py-2">
                  <v-col cols="2" class="mt-1">
                    <v-avatar v-if="messageInside.icon" color="red" size="32" >
                      <v-icon color="white" small>{{messageInside.icon}}</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10" class="pr-0">
                    <p class="message-inside-title">{{ messageInside.title }}</p>
                    <p class="message-inside-text">{{ messageInside.text }}</p>
                    <p v-if=" messageInside.remember" class="message-inside-remember mt-2"><v-icon class="mr-2" style="color: lightgray" small>far fa-bell</v-icon>{{ messageInside.remember }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import Back from '@/components/covid/back-arrow/index'
import MessageService from "../../services/socialnetwork/MessageService";
import store from "../../store/store";
import {mapGetters, mapMutations} from "vuex";
import moment from "moment"
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import pulseIcon from '../../components/pulse-icon';
export default {
  name: "chat",
  components: {
    Back,
    pulseIcon
  },
  data: () => ({
    isPushNotification: false,
    activeFilter: false,
    chatBots: [],
    newMessagesCount: 0,
    newChatCount: 0,
  }),
  async created () {
    this.setUserIdAndResetAll(this.$auth.user() && this.$auth.user().id ? this.$auth.user().id : 0);
    this._messageService = new MessageService();
    this._socialNetworkService = new SocialNetworkService();

    moment.locale('pt-br');
    this.loadMessages();
    await this.listChatBot();

    if(this.$route.params && this.$route.params.isPushNotification){
      this.isPushNotification = this.$route.params.isPushNotification
    }

    if(this.$route.params.activeFilter){
      this.activeFilter = this.$route.params.activeFilter
    }else if((this.isPushNotification) || (this.$route.query.activeFilter && this.$route.query.hashtagEnable)){
      this.activeFilter = this.$route.query.activeFilter;
      this.loading(true);
      setTimeout(() => {
        this.$router.push({name: 'chat'});
      }, 500);
    }
  },
  computed: {
    getActiveFilter(){
      return this.activeFilter;
    }
  },
  methods:{
    ...mapMutations(['loading', 'setMessagesGroupingBySender', 'setChatBotMessages', 'setChatBots', 'setUserIdAndResetAll']),
    ...mapGetters(["getMessagesGroupingBySender", "getChatBotMessages"]),
    hasConnectionCompany(){
      return this.$auth.user().hasCompanyConnection;
    },
    loadMessages(){
      this.loading(true);
      this.messagesGroupingBySender = this.getMessagesGroupingBySender();

      this._messageService.GetMessagesGroupingBySender()
        .then((response) => {
          this.setMessagesGroupingBySender(response.data);
        })
        .catch(error => {
          store.commit('showmsg', { text: "Erro ao listar Mensagens", type: "error" });
        })
        .finally(() => {
          this.newMessagesCount = 0;
          this.loading(false);

          let messages = this.getMessagesGroupingBySender();

          messages.forEach((message) => {
            if(message && message.unreadMessages)
              this.newMessagesCount += message.unreadMessages
          })
        })
    },
    showNotifications(sender){
        this.$router.push({ name: 'notifications', params: { sender } })
    },
    showChat(chatBot){
      this.$router.push({ name: 'chat', params: { chatBot } })
    },
    getMessageDate(date){
        const today = moment().format('L');
        const messageDate = moment(date).format('L');
        return (today == messageDate) ? moment(date).format('LT') : moment(date).format("DD [de] MMMM");
    },
    async listChatBot(){
      this.loading(true);
      await this._socialNetworkService.listAllChatBotEntity()
              .then((response) => {
                this.chatBots = response.data;
                this.setChatBots(this.chatBots);
                if(this.getChatBotMessages().length == 0){
                    let chatbotMessages = [{chatbot: response.data[0].id, messages:  [{body: response.data[0].initialMessage, type:'bot', messageClass: 'bot-message'}]}];
                    this.setChatBotMessages(chatbotMessages);
                  }
              })
              .catch(error => {
                store.commit('showmsg', { text: "Erro ao listar Chat", type: "error" });
              })
              .finally(() => {
                this.newChatCount = 0;
                let chats = this.chatBots;

                chats.forEach((chat) => {
                  if(chat && chat.unreadMessages)
                    this.newChatCount += chat.unreadMessages
                })

                if(this.isPushNotification && this.chatBots.length){
                  this.showChat(this.chatBots[0])
                }

                this.loading(false);
              })
    }
  }
}
</script>

<style scoped>
.centralize {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.message-time{
  font-family: "Open Sans";
  font-size: 14px;
  color: gray;
}

.message-sender {
  font-size: 18px;
}

.message-body {
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: gray;
}

.notification-header{
    position: relative;
    z-index: 1;
    width: 100%;
}

.notification-body {
    max-height: calc(100vh - 100px) !important;
    overflow: visible !important;
    position: relative;

}

.list-card {
    background-blend-mode: multiply !important;
    border-radius: 20px !important;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
}

.option-list-card {
    width: 100% !important;
    height: 65px;
}

.message-inside-card {
    max-width: 97% !important;
    min-width: 97% !important;
    background-color: #532E88;
    border-radius: 25px 25px 25px 25px !important;
}

.message-inside-title{
    color: white;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 5px;
}

.message-inside-text {
    color: white;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
}

.message-inside-remember {
    color: lightgray;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
}
</style>


