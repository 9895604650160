/* eslint-disable */
import HttpService from '../HttpService';
export default class SocialNetworkService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`socialnetwork`);
  }

  GetAllMessages() {
    return this._httpService.get(`/user_messages/getMessages`);
  }

  GetMessagesGroupingBySender() {
    return this._httpService.get(`/user_messages/getMessagesGroupingBySender`);
  }

  GetMessagesBySender(senderEntityId) {
    return this._httpService.get(`/user_messages/getMessagesBySender/${senderEntityId}`);
  }

  GetUnreadMessages() {
    return this._httpService.get(`/user_messages/getUnreadMessages`);
  }

  CountUnreadMessages() {
    return this._httpService.get(`/user_messages/countUnreadMessages`);
  }

  MarkMessageAsRead(id) {
    return this._httpService.post(`/user_messages/markMessageAsRead/${id}`);
  }

  async IsDisabledMenuMessageria() {
    return await this._httpService.get('/messages/isDisabledMenuMessageria');
  }
}

